<template>
  <div class="tool-container">
    <v-tabs
      class="tabs"
      grow
      v-model="tab"
    >
      <v-tab :value="tabEnum.partialRender">
        部分レンダリング
      </v-tab>
      <v-tab
        :value="tabEnum.paint"
        disabled
      >
        ペイント
      </v-tab>
    </v-tabs>

    <v-card
      v-if="tab === tabEnum.partialRender"
      class="tool-content"
    >
      <div class="drawing-controls">
        <div class="undo-redo-btns">
          <v-tooltip
            text="Undo"
            location="bottom"
          >
            <template #activator="{ props: tooltipProps }">
              <v-btn
                v-bind="tooltipProps"
                :disabled="!canUndo"
                variant="text"
                size="small"
                icon
                @click="$emit('undo')"
              >
                <v-icon>mdi-arrow-u-left-top</v-icon>
              </v-btn>
            </template>
          </v-tooltip>
          <v-tooltip
            text="Redo"
            location="bottom"
          >
            <template #activator="{ props: tooltipProps }">
              <v-btn
                v-bind="tooltipProps"
                :disabled="!canRedo"
                variant="text"
                size="small"
                icon
                @click="$emit('redo')"
              >
                <v-icon>mdi-arrow-u-right-top</v-icon>
              </v-btn>
            </template>
          </v-tooltip>
        </div>
        <v-tooltip
          text="Clear"
          location="bottom"
        >
          <template #activator="{ props: tooltipProps }">
            <v-btn
              v-bind="tooltipProps"
              :disabled="!hasContent"
              variant="text"
              size="small"
              icon
              @click="$emit('clear')"
            >
              <v-icon>mdi-trash-can-outline</v-icon>
            </v-btn>
          </template>
        </v-tooltip>
      </div>

      <v-divider
        class="divider"
        :vertical="isMobile"
      />

      <div class="drawing-tools">
        <v-btn
          :class="{ active: selectedTool === tool.key }"
          rounded="rounded-lg"
          v-for="tool in tools"
          :key="tool.name"
          @click="canvasStore.setSelectedTool(tool.key)"
        >
          <v-icon>{{ tool.icon }}</v-icon>
          <span v-if="!isMobile">{{ tool.name }}</span>
        </v-btn>
      </div>

      <v-divider
        class="divider"
        :vertical="isMobile"
      />

      <v-card-text
        v-if="selectedToolInfo"
        class="tools-control"
      >
        <div v-if="!isMobile">
          {{ `${selectedToolInfo.name}サイズ` }}
        </div>
        <v-slider
          min="1"
          max="100"
          step="1"
          hide-details
          density="compact"
          v-model="strokeWidth"
        />
      </v-card-text>
    </v-card>
  </div>
</template>

<script setup lang="ts">
import { ToolEnum } from '~/constants/drawing'

interface Tool {
  key: ToolEnum
  name: string
  icon: string
}

const props = defineProps<{
  canUndo: boolean
  canRedo: boolean
  hasContent: boolean
  isMobile: boolean
}>()
defineEmits<{
  (e: 'undo'): void
  (e: 'redo'): void
  (e: 'clear'): void
}>()

const { canUndo, canRedo, hasContent, isMobile } = toRefs(props)

const tools: Tool[] = [
  { key: ToolEnum.Brush, name: 'ブラシ', icon: 'mdi-brush-variant' },
  { key: ToolEnum.Eraser, name: '消しゴム', icon: 'mdi-eraser' },
]

enum tabEnum {
  partialRender = 'partialRender',
  paint = 'paint',
}

const canvasStore = useCanvasStore()
const { strokeWidth, selectedTool } = storeToRefs(canvasStore)
const selectedToolInfo = computed(() => tools.find(tool => tool.key === selectedTool.value))
const tab = ref(tabEnum.partialRender)
</script>

<style lang="scss" scoped>
@import '@/assets/css/_variables.scss';

.tool-container {
  width: 100%;
  height: fit-content !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  .tabs {
    width: 100%;

    .v-tab {
      font-weight: 700;
    }
    :deep(.v-tab-item--selected) {
      background: #253348;
      border: 1px solid #526380;
      border-top-left-radius: 0.4rem;
      border-top-right-radius: 0.4rem;
    }
  }

  .tool-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    padding: 24px 12px;
    background: #202835 !important;
    border: 1px solid #526380;
    border-radius: 0;
    border-bottom-left-radius: 0.4rem;
    border-bottom-right-radius: 0.4rem;
    width: 100%;

    @media (max-width: 768px) {
      flex-direction: row;
    }

    &.invisible {
      opacity: 0;
      pointer-events: none;
    }

    .divider {
      width: 100%;
      color: #525f75;
      opacity: 1;
    }

    .drawing-controls {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      .undo-redo-btns {
        display: flex;
        align-items: center;
      }
    }

    .drawing-tools {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      width: 100%;
      gap: 4px;

      @media (max-width: 768px) {
        flex-direction: row;
        gap: 16px;
      }

      > .v-btn {
        height: auto;
        width: 100%;
        padding: 12px 16px;
        font-weight: 700;

        @media (max-width: 768px) {
          padding: 4px;
          width: 28px !important;
          height: 28px !important;
          min-width: 28px !important;
          display: flex;
          aspect-ratio: 1;
          align-items: center;
          justify-content: center;
        }

        &.active {
          background: white;
          color: black;
        }
      }
    }

    :deep(.v-card-text) {
      padding: 8px;
    }

    .tools-control {
      display: flex;
      flex-direction: column;
      width: 100%;

      .v-slider {
        :deep(.v-slider-track__fill) {
          background-color: white;
          border-color: white;
        }
        :deep(.v-slider-thumb__surface) {
          border: 2px solid white;
        }
      }
    }
  }
  .v-btn {
    background: none;
  }
}
</style>
